import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logoNoBack } from "../../utils/importStaticFromDrive";
import HeightSpacer from "../common/HeightSpacer";
import { LINKS } from "../../utils/constants";
import { MailOutline } from "@mui/icons-material";

const LogoAndContact = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box component="img" src={logoNoBack} alt="logo" sx={{ maxWidth: 150 }} />
      <Typography variant="h5">{t("footer.logoText")}</Typography>
      <HeightSpacer height={16} />
      <Button
        variant="text"
        sx={{ textTransform: "none" }}
        onClick={() => {
          window.open(`mailto:${LINKS.EMAIL}`, "_self");
        }}
      >
        <MailOutline sx={{ mx: 1 }} />
        {LINKS.EMAIL}
      </Button>
    </Box>
  );
};

export default LogoAndContact;
