import { createTheme } from "@mui/material";
export const THEME = createTheme({
  typography: { fontFamily: "Ubuntu, sans-serif" },
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(236, 194, 72)",
    },
    secondary: {
      main: "rgb(79, 216, 235)",
    },
    error: {
      main: "rgb(255, 180, 171)",
    },
    warning: {
      main: "rgb(232, 225, 217)",
    },
    success: {
      main: "rgb(173, 207, 173)",
    },
    info: {
      main: "rgba(101,94,64,0.82)",
    },
    black: {
      main: "#000",
    },
    white: {
      main: "#fff",
    },
    mrAnxiety: {
      main: "#d1b4a0",
    },
    bambino: { main: "#cad4ed" },
    feelings: { main: "#a8e0eb" },
    superduper: { main: "#a8e391" },
    background: {
      default: "#000018",
    },
  },
});

export const GENERAL = {
  EMPTY_STRING: "",
  MAX_MOBILE_WIDTH: 600,
  IMAGE_ZOOM_Z_INDEX: 100,
  SECTIONS: {
    PROJECTS: "projects",
    STATEMENTS: "statements",
    ABOUT: "about",
    SPECIAL: "special",
  },
};

export const ROUTES = {
  HOME: "/",
  NOT_FOUND: "/*",
  APP_TERMS_OF_USE: "/terms-of-use",
  APP_PRIVACY_POLICY: "/privacy-policy",
  APP_END_USER_LICENSE_AGREEMENT: "/eula",
};

export const LINKS = {
  INSTAGRAM: "https://www.instagram.com/",
  FACEBOOK: "https://www.facebook.com/",
  EMAIL: "magaldigital.com@gmail.com",
  WEBSITE: "https://www.magaldigital.com",
  MEDIA: "@magaldigital.com",
};

export const LANGUAGES = {
  English: {
    CODE: "en",
    VALUE: "English",
  },
  Hebrew: {
    CODE: "he",
    VALUE: "עברית",
  },
  Arabic: {
    CODE: "ar",
    VALUE: "العربية",
  },
  Catalan: {
    CODE: "ca",
    VALUE: "Català",
  },
  Chinese: {
    CODE: "zh",
    VALUE: "中国人",
  },
  Croatian: {
    CODE: "hr",
    VALUE: "Hrvatski",
  },
  Czech: {
    CODE: "cs",
    VALUE: "čeština",
  },
  Danish: {
    CODE: "da",
    VALUE: "dansk",
  },
  Finnish: {
    CODE: "fi",
    VALUE: "Suomalainen",
  },
  French: {
    CODE: "fr",
    VALUE: "Français",
  },
  German: {
    CODE: "de",
    VALUE: "Deutsch",
  },
  Greek: {
    CODE: "el",
    VALUE: "Ελληνικά",
  },
  Hindi: {
    CODE: "hi",
    VALUE: "हिंदी",
  },
  Hungarian: {
    CODE: "hu",
    VALUE: "Magyar",
  },
  Indonesian: {
    CODE: "id",
    VALUE: "bahasa Indonesia",
  },
  Italian: {
    CODE: "it",
    VALUE: "Italiano",
  },
  Japanese: {
    CODE: "ja",
    VALUE: "日本",
  },
  Korean: {
    CODE: "ko",
    VALUE: "한국인",
  },
  Malay: {
    CODE: "ms",
    VALUE: "Melayu",
  },
  Norwegian: {
    CODE: "nb",
    VALUE: "norsk",
  },
  Polish: {
    CODE: "pl",
    VALUE: "Polski",
  },
  Portuguese: {
    CODE: "pt",
    VALUE: "Português",
  },
  Romanian: {
    CODE: "ro",
    VALUE: "Română",
  },
  Russian: {
    CODE: "ru",
    VALUE: "Русский",
  },
  Slovak: {
    CODE: "sk",
    VALUE: "slovenský",
  },
  Spanish: {
    CODE: "es",
    VALUE: "español",
  },
  Swedish: {
    CODE: "sv",
    VALUE: "svenska",
  },
  Thai: {
    CODE: "th",
    VALUE: "ภาษาไทย",
  },
  Turkish: {
    CODE: "tr",
    VALUE: "Türk",
  },
  Ukrainian: {
    CODE: "uk",
    VALUE: "українська",
  },
  Vietnamese: {
    CODE: "vi",
    VALUE: "Tiếng Việt",
  },
};

export const LANGUAGES_ARR = [
  {
    CODE: "en",
    VALUE: "English",
  },
  {
    CODE: "he",
    VALUE: "עברית",
  },
];
