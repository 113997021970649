import React from "react";
import { Box } from "@mui/material";
import HeightSpacer from "../common/HeightSpacer";
import Profile from "../home/about/Profile";

const SocialMedia = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <HeightSpacer height={16} />
      {/*<Box>*/}
      {/*  <Button*/}
      {/*    variant="text"*/}
      {/*    sx={{ textTransform: "none" }}*/}
      {/*    onClick={() => {*/}
      {/*      window.open(LINKS.FACEBOOK);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Facebook color="inherit" fontSize="large" />*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    variant="text"*/}
      {/*    sx={{ textTransform: "none" }}*/}
      {/*    onClick={() => {*/}
      {/*      window.open(LINKS.INSTAGRAM);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Instagram color="inherit" fontSize="large" />*/}
      {/*  </Button>*/}
      {/*</Box>*/}
      {/*<HeightSpacer />*/}
      {/*<Typography variant="body1">{LINKS.MEDIA}</Typography>*/}
      {/*<HeightSpacer />*/}
      <Box sx={{ width: 300 }}>
        <Profile />
      </Box>
    </Box>
  );
};

export default SocialMedia;
