import React, { useMemo } from "react";
import { GENERAL } from "../../../utils/constants";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Profile from "./Profile";

const AboutContainer = () => {
  const { t } = useTranslation();

  const getText = useMemo(() => {
    return t("about.text", { returnObjects: true });
  }, [t]);

  return (
    <>
      <Box py={5} sx={{ backgroundColor: "#000018" }}>
        <Container id={GENERAL.SECTIONS.ABOUT}>
          <Typography variant={"h6"}>{t("about.title")}</Typography>
          <Divider />
          <Grid
            container
            py={5}
            justifyContent={"center"}
            alignItems={"center"}
            flexWrap={"wrap-reverse"}
          >
            <Grid container item md={6}>
              {getText?.map((text) => {
                return (
                  <Box key={text} paddingY={2}>
                    <Typography variant={"h6"}>{text}</Typography>
                  </Box>
                );
              })}
            </Grid>
            <Grid
              container
              item
              md={6}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Profile />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AboutContainer;
