import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const AgreementButton = ({ text, route, element, toggle }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (element) {
      const elementDOM = document.getElementById(element);
      if (elementDOM) {
        elementDOM.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (route) {
      navigate(route);
    }
    if (toggle) {
      toggle();
    }
  };

  return (
    <Button
      style={{ textTransform: "capitalize" }}
      fullWidth
      sx={{ minWidth: 150 }}
      onClick={handleClick}
      // href={element}
    >
      {text}
    </Button>
  );
};

export default AgreementButton;
