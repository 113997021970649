import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  bambinoCarousel,
  bambinoLogo,
  feelingsLogo,
  mrAnxiety,
  mrAnxietyAppCarousel,
  springCarousel,
  superDuperCarousel,
  superduperLogo,
} from "../../../utils/importStaticFromDrive";
import HomeSectionProject from "./HomeSectionProject";
import { Apple, Google, Language } from "@mui/icons-material";
import { Container, Divider, Typography } from "@mui/material";
import { GENERAL } from "../../../utils/constants";

const HomeProjectsContainer = () => {
  const { t } = useTranslation();

  const getProjects = useMemo(() => {
    return [
      {
        title: t("projects.mrAnxiety.title"),
        subtitle: t("projects.mrAnxiety.subtitle"),
        text: t("projects.mrAnxiety.text"),
        avatar: mrAnxiety,
        backgroundColor: "#2c3f41",
        linkColor: "mrAnxiety",
        isReversed: false,
        links: [
          {
            text: t("projects.website"),
            icon: <Language />,
            link: "https://misteranxiety.com/mobile-app",
          },
          {
            text: t("projects.appStore"),
            icon: <Apple />,
            link: "https://apps.apple.com/app/mranxiety-breathe-and-meditate/id6475256909",
          },
          {
            text: t("projects.googlePlay"),
            icon: <Google />,
            link: "https://play.google.com/store/apps/details?id=com.chaimagal.mranxiety",
          },
        ],
        technologies: [],
        screenshots: mrAnxietyAppCarousel,
      },
      {
        title: t("projects.feelings.title"),
        subtitle: t("projects.feelings.subtitle"),
        text: t("projects.feelings.text"),
        avatar: feelingsLogo,
        backgroundColor: "#3c110f",
        linkColor: "feelings",
        isReversed: true,
        links: [
          {
            text: t("projects.website"),
            icon: <Language />,
            link: "https://springinmylife.com/app",
          },
          {
            text: t("projects.appStore"),
            icon: <Apple />,
            link: "https://apps.apple.com/app/id6471379393",
          },
          {
            text: t("projects.googlePlay"),
            icon: <Google />,
            link: "https://play.google.com/store/apps/details?id=com.chaimagal.thefeelingsgame",
          },
        ],
        technologies: [],
        screenshots: springCarousel,
      },
      {
        title: t("projects.bambino.title"),
        subtitle: t("projects.bambino.subtitle"),
        text: t("projects.bambino.text"),
        avatar: bambinoLogo,
        backgroundColor: "#3d3334",
        linkColor: "bambino",
        isReversed: false,
        links: [
          {
            text: t("projects.website"),
            icon: <Language />,
            link: "https://bambinopregnancyandbabytracker.com/",
          },
          {
            text: t("projects.appStore"),
            icon: <Apple />,
            link: "https://apps.apple.com/app/id6473719824",
          },
          {
            text: t("projects.googlePlay"),
            icon: <Google />,
            link: "https://play.google.com/store/apps/details?id=com.chaimagal.bambinopregnancyandbabytracker",
          },
        ],
        technologies: [],
        screenshots: bambinoCarousel,
      },
      {
        title: t("projects.superduper.title"),
        subtitle: t("projects.superduper.subtitle"),
        text: t("projects.superduper.text"),
        avatar: superduperLogo,
        backgroundColor: "#565339",
        linkColor: "superduper",
        isReversed: true,
        links: [
          {
            text: t("projects.website"),
            icon: <Language />,
            link: "https://superdupershoppinglist.com/",
          },
          {
            text: t("projects.appStore"),
            icon: <Apple />,
            link: "https://apps.apple.com/app/superduper-shopping-list/id6463705245",
          },
          {
            text: t("projects.googlePlay"),
            icon: <Google />,
            link: "https://play.google.com/store/apps/details?id=com.chaimagal.superduper",
          },
        ],
        technologies: [],
        screenshots: superDuperCarousel,
      },
    ];
  }, [t]);
  return (
    <Container>
      <div id={GENERAL.SECTIONS.PROJECTS}>
        {/*<HeightSpacer height={100} />*/}
        <Typography variant={"h6"}>{t("projects.title")}</Typography>
        <Divider />
      </div>
      {getProjects?.map((project) => {
        return <HomeSectionProject key={project?.title} project={project} />;
      })}
    </Container>
  );
};

export default HomeProjectsContainer;
