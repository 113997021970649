import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Typography } from "@mui/material";
import lottie from "lottie-web";
import Lottie from "lottie-react";
import { motion, useTransform, useScroll } from "framer-motion";
import CenterFlexDiv from "./common/CenterFlexDiv";
import stars from "../assets/lottie/stars.json";
import arrow from "../assets/lottie/arrow.json";
import { logoNoBack } from "../utils/importStaticFromDrive";
import FlexSpacer from "./common/FlexSpacer";
import useWindowDimensions from "../utils/useWindowDimensions";
import { useTranslation } from "react-i18next";
import { GENERAL } from "../utils/constants";
import HeightSpacer from "./common/HeightSpacer";
import { throttle } from "lodash";

// Memoize FlexSpacer component
const MemoizedFlexSpacer = React.memo(FlexSpacer);

// Memoize CenterFlexDiv component
const MemoizedCenterFlexDiv = React.memo(CenterFlexDiv);

const ImageZoomScrollText = () => {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  const { width, height } = useWindowDimensions();
  const { scrollY } = useScroll();

  const animationContainer = useRef(null);

  const isMobile = useMemo(() => width < 600, [width]);
  const zoomThreshold = 1;
  const zoomFactor = useMemo(
    () => (scrollPosition / (zoomThreshold * height)) * 2,
    [scrollPosition, height, zoomThreshold]
  );

  const scaleBaseline = useMemo(
    () => (isMobile ? (height / width) * 3 : (height / width) * 2),
    [isMobile, height, width]
  );

  const scrollAccordingToViewHeight = useMemo(
    () => scrollPosition / (zoomThreshold * height),
    [scrollPosition, height, zoomThreshold]
  );

  const isShowLogo = useMemo(
    () => scrollAccordingToViewHeight < 2,
    [scrollAccordingToViewHeight]
  );

  const componentHeight = useMemo(
    () => zoomThreshold * height * 2,
    [height, zoomThreshold]
  );

  const position = useMemo(
    () => (scrollPosition < componentHeight - height ? "fixed" : "absolute"),
    [scrollPosition, componentHeight, height]
  );

  useEffect(() => {
    window.addEventListener(
      "scroll",
      throttle(() => {
        setScrollPosition(window.scrollY);
      }, 50)
    );
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: stars,
    });
    return () => {
      window.removeEventListener(
        "scroll",
        throttle(() => {
          setScrollPosition(window.scrollY);
        }, 50)
      );
      anim.destroy();
    };
  }, []);

  const scale = useTransform(scrollY, [0, componentHeight], [1, 5]);
  const opacity = useTransform(scrollY, [0, componentHeight - height], [1, 0]);

  return (
    <div
      className="zoom-container"
      style={{
        zIndex: isShowLogo ? GENERAL.IMAGE_ZOOM_Z_INDEX : 0,
        height: componentHeight,
      }}
    >
      <div
        className="background-image"
        ref={animationContainer}
        style={{
          transform: `scale(${scaleBaseline + zoomFactor})`,
          position,
          bottom: 0,
        }}
      />
      <div
        style={{
          position,
          height: "100vh",
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bottom: 0,
        }}
      >
        <MemoizedFlexSpacer />
        <MemoizedCenterFlexDiv style={{ flexDirection: "column" }}>
          <MemoizedFlexSpacer />
          <motion.img
            id="logo"
            src={logoNoBack}
            alt={"Magal Digital"}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
            style={{ scale, width: "50%" }}
          />
          <motion.div
            style={{ opacity }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
          >
            <Typography
              id="title"
              sx={{ typography: { xs: "h3", md: "h1" } }}
              style={{ textAlign: "center" }}
              gutterBottom
            >
              {t("header.title")}
            </Typography>
            <Typography
              id="subtitle"
              sx={{
                typography: { xs: "h6", md: "h4" },
              }}
              style={{ textAlign: "center" }}
              gutterBottom
            >
              {t("header.subTitle")}
            </Typography>
          </motion.div>
        </MemoizedCenterFlexDiv>
        <MemoizedFlexSpacer />
        <Button
          onClick={() => {
            window.scrollTo({ top: componentHeight, behavior: "smooth" });
          }}
        >
          <Lottie animationData={arrow} loop={true} style={{ width: 100 }} />
        </Button>
        <HeightSpacer />
      </div>
    </div>
  );
};

export default ImageZoomScrollText;
