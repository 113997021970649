import React from "react";
import "./App.scss";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router";
import Home from "./screens/Home";
import { ROUTES, THEME } from "./utils/constants";
import { useTranslation } from "react-i18next";
import NotFound from "./screens/NotFound";

function App() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Helmet>
        <title>{t("helmet.home")}</title>
      </Helmet>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />}></Route>
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
