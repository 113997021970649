import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { profilePicture } from "../../../utils/importStaticFromDrive";
import LinkButtons from "../../common/LinkButtons";
import { LinkedIn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();

  const getLinks = useMemo(() => {
    return [
      {
        text: t("projects.linkedIn"),
        icon: <LinkedIn />,
        link: "https://www.linkedin.com/in/chai-magal/l",
      },
      // {
      //   text: t("projects.facebook"),
      //   icon: <Facebook />,
      //   link: "https://www.facebook.com/chai.magal",
      // },
      // {
      //   text: t("projects.instagram"),
      //   icon: <Instagram />,
      //   link: "https://www.instagram.com/chaimagal",
      // },
    ];
  }, [t]);
  return (
    <>
      <Box borderRadius={2} p={5}>
        <Box
          component={"img"}
          alt={"profile-picture"}
          src={profilePicture}
          width={"100%"}
          height={"100%"}
          borderRadius={2}
          sx={{ objectFit: "contain" }}
        />
      </Box>
      <Grid container item justifyContent={"center"} alignItems={"center"}>
        <LinkButtons links={getLinks} color={"primary"} />
      </Grid>
    </>
  );
};

export default Profile;
