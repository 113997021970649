import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import CenterFlexDiv from "../../common/CenterFlexDiv";
import HeightSpacer from "../../common/HeightSpacer";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { GENERAL } from "../../../utils/constants";
import LinkButtons from "../../common/LinkButtons";
import ImagesCarousel from "../../common/ImagesCarousel";

const SIZE_XS = 100;
const SIZE_MD = 200;
const PADDING_XS = 2;
const PADDING_MD = 4;
const BORDER_RADIUS = 2;

const HomeSectionProject = ({
  project: {
    title,
    subtitle,
    text,
    avatar,
    links,
    isReversed,
    backgroundColor,
    linkColor,
    linkVariant,
    screenshots,
  },
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < GENERAL.MAX_MOBILE_WIDTH;

  return (
    <>
      <CenterFlexDiv style={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            padding: { xs: PADDING_XS, md: PADDING_MD },
            marginY: { xs: PADDING_XS, md: PADDING_MD },
            backgroundColor,
            borderRadius: BORDER_RADIUS,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile
                ? isReversed
                  ? "column"
                  : "column-reverse"
                : "row",
              flexWrap: isReversed ? "wrap-reverse" : "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isReversed && screenshots?.length > 0 && (
              <Box>
                <ImagesCarousel images={screenshots} />
              </Box>
            )}
            <Box
              sx={{
                paddingY: 2,
                padding: { md: PADDING_MD },
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                alt={title}
                sx={{
                  width: { xs: SIZE_XS, md: SIZE_MD },
                  height: { xs: SIZE_XS, md: SIZE_MD },
                  borderRadius: { xs: SIZE_XS, md: SIZE_MD },
                  marginY: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={avatar}
              />
              <Typography
                sx={{
                  typography: { xs: "h4", md: "h2" },
                  textAlign: "center",
                }}
                gutterBottom
              >
                {title}
              </Typography>
              <Typography
                sx={{ typography: { xs: "h6", md: "h4" } }}
                gutterBottom
              >
                {subtitle}
              </Typography>
              <HeightSpacer />
              <Divider />
              <HeightSpacer />
              <Typography
                sx={{ typography: { xs: "body2", md: "body1" } }}
                gutterBottom
              >
                {text}
              </Typography>
              <HeightSpacer />
              {links && links?.length > 0 && (
                <LinkButtons
                  links={links}
                  color={linkColor}
                  variant={linkVariant}
                />
              )}
            </Box>
            {isReversed && screenshots?.length > 0 && (
              <Box>
                <ImagesCarousel images={screenshots} />
              </Box>
            )}
          </Box>
        </Paper>
      </CenterFlexDiv>
    </>
  );
};

export default HomeSectionProject;
