import React, { useMemo } from "react";
import BlankPage from "../components/common/BlankPage";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HomeProjectsContainer from "../components/home/projects/HomeProjectsContainer";
import { GENERAL } from "../utils/constants";
import AboutContainer from "../components/home/about/AboutContainer";
import SpecialContainer from "../components/home/special/SpecialContainer";
import ImageZoomWrapper from "../components/ImageZoomWrapper";

const Home = () => {
  const { t } = useTranslation();

  const getButtons = useMemo(() => {
    return [
      // {
      //   text: t("statements.title"),
      //   element: GENERAL.SECTIONS.STATEMENTS,
      //   route: null,
      // },
      {
        text: t("about.title"),
        element: GENERAL.SECTIONS.ABOUT,
        route: null,
      },
      {
        text: t("projects.title"),
        element: GENERAL.SECTIONS.PROJECTS,
        route: null,
      },
      {
        text: t("special.title"),
        element: GENERAL.SECTIONS.SPECIAL,
        route: null,
      },
    ];
  }, [t]);

  return (
    <>
      <Helmet>
        <title>{t("helmet.home")}</title>
      </Helmet>
      <ImageZoomWrapper />
      <BlankPage buttons={getButtons}>
        <AboutContainer />
        <HomeProjectsContainer />
        <SpecialContainer />
      </BlankPage>
    </>
  );
};

export default Home;
