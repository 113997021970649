import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  liveFromIsraelLogo,
  mrAnxiety,
  mrAnxietyBookCarousel,
} from "../../../utils/importStaticFromDrive";
import {
  Facebook,
  Instagram,
  Language,
  Store,
  YouTube,
} from "@mui/icons-material";
import { GENERAL } from "../../../utils/constants";
import { Container, Divider, Typography } from "@mui/material";
import HomeSectionProject from "../projects/HomeSectionProject";

const SpecialContainer = () => {
  const { t } = useTranslation();

  const getProjects = useMemo(() => {
    return [
      {
        title: t("special.mrAnxiety.title"),
        subtitle: t("special.mrAnxiety.subtitle"),
        text: t("special.mrAnxiety.text"),
        avatar: mrAnxiety,
        backgroundColor: "#2c3f41",
        linkColor: "mrAnxiety",
        isReversed: false,
        links: [
          {
            text: t("projects.store"),
            icon: <Store />,
            link: "https://nivbook.co.il/product/%d7%9e%d7%a8-%d7%97%d7%a8%d7%93%d7%94/",
          },
          {
            text: t("projects.website"),
            icon: <Language />,
            link: "https://misteranxiety.com/",
          },
        ],
        technologies: [],
        screenshots: mrAnxietyBookCarousel,
      },
      {
        title: t("special.livefromisrael.title"),
        subtitle: t("special.livefromisrael.subtitle"),
        text: t("special.livefromisrael.text"),
        avatar: liveFromIsraelLogo,
        isReversed: true,
        backgroundColor: "#2d3840",
        linkColor: "white",
        links: [
          {
            text: t("projects.youtube"),
            icon: <YouTube />,
            link: "https://www.youtube.com/@livefromisrael_",
          },
          {
            text: t("projects.facebook"),
            icon: <Facebook />,
            link: "https://facebook.com/livefromisrael",
          },
          {
            text: t("projects.instagram"),
            icon: <Instagram />,
            link: "https://instagram.com/livefromisrael",
          },
        ],
        technologies: [],
        screenshots: [],
      },
    ];
  }, [t]);
  return (
    <Container>
      <div id={GENERAL.SECTIONS.SPECIAL}>
        <Typography variant={"h6"}>{t("special.title")}</Typography>
        <Divider />
      </div>
      {getProjects?.map((project) => {
        return <HomeSectionProject key={project?.title} project={project} />;
      })}
    </Container>
  );
};

export default SpecialContainer;
