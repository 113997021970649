import React from "react";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoAndContact from "./LogoAndContact";
import SocialMedia from "./SocialMedia";
import HeightSpacer from "../common/HeightSpacer";

const Footer = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-evenly",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          margin: 0,
          backgroundColor: palette.background.default,
        }}
      >
        <Container>
          <Box
            sx={{
              justifyContent: "space-evenly",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              paddingY: 8,
            }}
          >
            <LogoAndContact />
            <SocialMedia />
          </Box>
        </Container>
        <Typography variant="body1">{t("footer.allRightsReserved")}</Typography>
        <HeightSpacer />
      </Box>
    </>
  );
};

export default Footer;
