import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions"; // Hook for window dimensions
import { GENERAL } from "../../utils/constants"; // Assuming constants are defined here

const ImagesCarousel = ({ images }) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const mobile = width < GENERAL.MAX_MOBILE_WIDTH;
  const imageWidth = mobile ? 250 : 350;

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  return (
    <Box sx={{ maxWidth: imageWidth, flexGrow: 1, position: "relative" }}>
      <Box
        component="img"
        src={images[activeStep]}
        alt={`Slide ${activeStep}`}
        sx={{
          width: imageWidth, // Dynamic width
          height: imageWidth * 2.164, // Dynamic height
          objectFit: "cover",
          borderRadius: 2,
        }}
      />

      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          mt: 2,
          maxWidth: imageWidth,
          flexGrow: 1,
          borderRadius: 2,
        }}
        nextButton={
          <Button size="small" onClick={handleNext}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Box>
  );
};
export default ImagesCarousel;
