import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// import notfound from "../assets/gif/not-found.gif";
import BlankPage from "../components/common/BlankPage";
import notFound from "../assets/lottie/404.json";
import Lottie from "lottie-react";
import { Box } from "@mui/material";

const NotFound = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{t("helmet.notFound")}</title>
      </Helmet>
      <BlankPage>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Lottie
            animationData={notFound}
            loop={true}
            style={{ width: "50%" }}
          />
        </Box>
      </BlankPage>
    </>
  );
};

export default NotFound;
