import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  Toolbar,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Squash as Hamburger } from "hamburger-react";
import { useNavigate } from "react-router";
import { logoNoBack } from "../../utils/importStaticFromDrive";
import { GENERAL, ROUTES } from "../../utils/constants";
import AgreementButton from "./AgreementButton";
import TranslationMenu from "./TranslationMenu";

const TopNav = ({ buttons }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);

  const handleNavMenu = () => {
    setAnchorElNav((prev) => !prev);
    setBurgerNav((prev) => !prev);
  };

  return (
    <>
      <AppBar
        elevation={0}
        position={"sticky"}
        sx={{ backgroundColor: theme.palette.background.default }}
        style={{
          zIndex: GENERAL.IMAGE_ZOOM_Z_INDEX - 1,
        }}
      >
        <Container sx={{ px: 0 }}>
          <Toolbar sx={{ px: 1 }}>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(ROUTES.HOME);
              }}
              style={{
                textTransform: "capitalize",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box component={"img"} width={30} alt="logo" src={logoNoBack} />
              <Box width={5} />
              {t("helmet.home")}
            </Button>
            <Box sx={{ flex: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {buttons?.length > 0 &&
                buttons?.map((button, index) => (
                  <AgreementButton
                    key={button?.text + button?.route + index}
                    text={button?.text}
                    route={button?.route}
                    element={button?.element}
                  />
                ))}
            </Box>
            <TranslationMenu />
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                color: theme.palette.primary.main,
              }}
            >
              <Hamburger
                toggled={burgerNav}
                toggle={handleNavMenu}
                size={20}
                duration={0.2}
              />
            </Box>
          </Toolbar>
          <Collapse in={anchorElNav} timeout="auto" unmountOnExit>
            {buttons?.length > 0 && (
              <List>
                {buttons?.map((button, index) => (
                  <ListItem
                    key={button?.text + button?.route + index}
                    sx={{ justifyContent: "center" }}
                  >
                    <AgreementButton
                      text={button?.text}
                      route={button?.route}
                      element={button?.element}
                      toggle={handleNavMenu}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Collapse>
        </Container>
      </AppBar>
    </>
  );
};

export default TopNav;
