import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { Language } from "@mui/icons-material";
import { LANGUAGES_ARR } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const TranslationMenu = () => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (code) => {
    handleClose();
    i18n.changeLanguage(code);
    document.body.dir = i18n.dir();
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Language />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {LANGUAGES_ARR.map((lng) => {
          return (
            <MenuItem
              key={lng.VALUE}
              onClick={() => {
                handleChangeLanguage(lng.CODE);
              }}
            >
              {lng.VALUE}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default TranslationMenu;
