import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import ImageZoomScrollText from "./ImageZoomScrollText"; // Your main component
import { Box, Button } from "@mui/material";
import Lottie from "lottie-react";
import arrow from "../assets/lottie/arrow.json";

const ImageZoomWrapper = () => {
  const [isMounted, setIsMounted] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.1, // Component becomes unmounted when less than 10% is visible
    triggerOnce: false, // Keep observing
    initialInView: true,
  });

  useEffect(() => {
    if (!inView && isMounted) {
      setIsMounted(false);
      window.scrollTo(0, 50);
    }
  }, [inView, isMounted]);

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Button
          onClick={() => {
            setIsMounted(true); // Option to remount component (for testing)
          }}
        >
          <Lottie
            animationData={arrow}
            loop={true}
            style={{ width: 50, transform: "scale(-1)" }}
          />
        </Button>
      </Box>
      <div ref={ref}>{isMounted && <ImageZoomScrollText />}</div>
    </>
  );
};

export default ImageZoomWrapper;
