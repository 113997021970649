import React from "react";
import { Box, Button } from "@mui/material";
import WidthSpacer from "./WidthSpacer";

const LinkButtons = ({ links, color = "secondary", variant = "outlined" }) => {
  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {links?.map((link) => {
        return (
          <Box
            key={link?.text}
            sx={{
              flexDirection: "row",
              display: "flex",
              paddingY: 1,
            }}
          >
            <Button
              variant={variant}
              color={color}
              sx={{ textTransform: "unset" }}
              onClick={() => {
                window.open(link?.link);
              }}
            >
              {link?.icon}
              <WidthSpacer width={8} />
              {link?.text}
            </Button>
            <WidthSpacer />
          </Box>
        );
      })}
    </Box>
  );
};

export default LinkButtons;
