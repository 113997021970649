import { createSlice } from "@reduxjs/toolkit";
import { GENERAL } from "../../utils/constants";

const initialState = {
  scrollPosition: GENERAL.EMPTY_STRING,
};

export const localSlice = createSlice({
  name: "local",
  initialState,
  reducers: {
    setScrollPosition: (state, { payload }) => {
      state.scrollPosition = payload;
    },
  },
});

export const { setScrollPosition } = localSlice.actions;

export default localSlice.reducer;
