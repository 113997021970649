export const urlFromImageId = (id) =>
  `https://lh3.googleusercontent.com/d/${id}=w800?authuser=0`;
// `https://drive.google.com/thumbnail?id=${id}&sz=w800`;

export const superduperLogo = urlFromImageId(
  "1cqh05eKfr_XxSc7bFrJstSnnn5rXKSFH"
);
export const feelingsLogo = urlFromImageId("10QNGlqhlC_oc9pxorIA3vDctEqF23Eyb");
export const logoNoBack = urlFromImageId("1HgjftSPvw6PCyNvIL_dsbU7b2qEgVIAD");
export const bambinoLogo = urlFromImageId("12q-EKJPex_hh9R0Xcx8lhCD3ARrA37mH");
export const liveFromIsraelLogo = urlFromImageId(
  "1igtkAmksub8AyDl1yp20Mb0LIR_Ro2dB"
);
export const mrAnxiety = urlFromImageId("1shhEQDSpjKo05o9bgWffMY8wAvOLHRmc");
export const profilePicture = urlFromImageId(
  "1J8UJz7V2ptu9fP-GH7czz7Z1BV-ZgDnZ"
);

export const superDuperCarousel = [
  urlFromImageId("1ZOBCNyIVihC2WCIwyXh9CWRYxwXiviVl"),
  urlFromImageId("1rn2srQSh4uYbQIXCWg21iFhrrhosVv9K"),
  urlFromImageId("1BsFVM_xRfE3v1odVfbp3KdQ08d9NyaBe"),
  urlFromImageId("1g5pKx2MJ5NlTAXaL1MKZD079_2CxEo7s"),
  urlFromImageId("1BKgMvAgJwrpJAMi8mReCN6xO_J04ea6h"),
  urlFromImageId("1sVGSjmrI7esQ8cpNg6umf4i2knmbg8Gs"),
  urlFromImageId("1dBt6LlRXCnDl2zDg7foVfKn25sjn8aYK"),
];
export const mrAnxietyAppCarousel = [
  urlFromImageId("1nAe0MCnRPRP7k2LjjanKyw7Y5lNk_pds"),
  urlFromImageId("1LCVKUmFrDouuJBHH_rJkZP_13uT7YWq6"),
  urlFromImageId("1Nu5kGj8R-JnK06OQ9s7om47RjYwFeg16"),
  urlFromImageId("1nf6y8ZLAeVbNWqCxdV66KhDVIlqj1v3g"),
  urlFromImageId("1yIDkCDaZlb9usPQRILtKZ36HbJgmqYDM"),
  urlFromImageId("1Etd1ZZ7B9ee-XdpIWJipRkn-onKcoRcV"),
  urlFromImageId("1momZdQRxJwVB8_zWZm1dA_LTS5a6XMID"),
];
export const bambinoCarousel = [
  urlFromImageId("1maBmWeK4bIHzz-AesuxAMOMyEcProB_U"),
  urlFromImageId("1fmIwTNk0aEEX2B5L9bZFTSKC8x0OaXOl"),
  urlFromImageId("1XCCfLYL09WRXJfuMxKkto5fPj64EE2dg"),
  urlFromImageId("1Gwfy39ivpQlQu-oFF5zwFVW4Qq-BdDaU"),
  urlFromImageId("11muGMaYcYZS44Tpq5TQUEk8xDCRKNe50"),
  urlFromImageId("1h1_gvKXzaBO2M8GrzCH9GjGqsjNH_8-K"),
];
export const springCarousel = [
  urlFromImageId("1aWj4pRDttuizX0XoQDZDioHvPCwJR7I4"),
  urlFromImageId("1_b2Ki5kpWTQzMo8_0ocGLVuIWPOyb3J-"),
  urlFromImageId("1yBkDHE_FJxyq4mRa1FGBXafoM3pTg5I6"),
  urlFromImageId("1dlDEl5Ii6TM5q30pg2BKZbWcy9vIe46o"),
  urlFromImageId("1jFPbvkViAqXX8W-n4hFYUc5_emdk5ms5"),
  urlFromImageId("15MPt19VDxMpgM_58620Mp9qjvcVaXYkD"),
  urlFromImageId("1iNMnuf3fIk5OaF6Jo7ZRgkH4OKMJG959"),
];

export const mrAnxietyBookCarousel = [
  urlFromImageId("1uYU6iafzHNeaGw_7omNlwhPRSQVgTdx4"),
  urlFromImageId("1hFCUBwQQWJJoMy_l_4BAGD_DLeL3KDw-"),
  urlFromImageId("1-oxKjv26LgxurLUehkxrPWAALIEA7Lf0"),
];
