import React from "react";
import TopNav from "../topNav/TopNav";
import Footer from "../footer/Footer";

const BlankPage = ({ children, buttons }) => {
  return (
    <>
      <TopNav buttons={buttons} />
      {children}
      <Footer />
    </>
  );
};

export default BlankPage;
